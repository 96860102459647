import React, { useEffect, useState } from "react";
import Axios from "axios";
import { URL } from "../config/config";

import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import { Accordion } from "@mui/material";
import { AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function AwnserItem({ category, categoryId, designId }) {
  const [answer, setAnswer] = useState([]);
  const [expanded, setExpanded] = React.useState(false);

  const getAnswer = () => {
    Axios.get(`${URL}/answer/${categoryId}/${designId}`).then((response) => {
      setAnswer(response.data[0]);
      console.log("Answer Loaded");
    });
  };

  const testData = () => {
    console.log(answer);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    getAnswer();
  }, [categoryId]);

  return (
    <Box sx={{ mt: 3 }}>
      <Accordion
        expanded={expanded === category}
        onChange={handleChange(category)}
        sx={{ mt: 1 }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h5">{category}</Typography>
        </AccordionSummary>

        <Typography variant="body1">{answer.ANSWER}</Typography>
      </Accordion>
      {/* <button onClick={testData}></button> */}
    </Box>
  );
}

export default AwnserItem;
