import React, { useEffect, useState } from "react";
import "../styles/Questionnaire.css";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import { URL } from "../config/config";

import Questionnaire from "../components/Questionnaire";

import { Container } from "@mui/material";
import { Box } from "@mui/material";
import { Button } from "@mui/material";

function QuestionnairePage() {
  const [categories, setCategories] = useState([]);
  const [count, setCount] = useState(0);
  const [approach, setApproach] = useState([]);

  const navigate = useNavigate();

  const [responses, setResponses] = useState([]);
  const [designs, setDesigns] = useState();

  const [expanded, setExpanded] = React.useState(false);

  const proj_id = localStorage.getItem("proj_id");

  const updateDate = () => {
    Axios.put(`${URL}/project/date/${proj_id}`).then((response) => {
      if (response.data.error) {
        console.log(response.data.error);
      }
    });
  };

  const getCategories = () => {
    Axios.get(`${URL}/response/design/${proj_id}`).then((response) => {
      if (response.data.length > 0) setDesigns(response.data);
    });
    Axios.get(`${URL}/category/first`).then((response) => {
      setCategories(response.data);
      console.log("Categories Loaded");
    });
  };

  const getCount = () => {
    Axios.get(`${URL}/response/count/${proj_id}`).then((response) => {
      if (response.data[0].count === null) {
        setCount(1);
        getCategories();
      } else if (response.data[0].count >= 3) {
        // alert("Finished Questionnaire");
        navigate(`/project/report`);
      } else {
        setCount(parseInt(response.data[0].count) + 1);
        getCategories();
      }
      console.log("Count Loaded");
    });
  };
  const GetApproach = () => {
    Axios.get(`${URL}/project/approach/${proj_id}`).then((response) => {
      if (response.data[0].APPROACH === "Qualitative") {
        setApproach("2");
      }
      if (response.data[0].APPROACH === "Quantitative") {
        setApproach("1");
      }
    });
  };

  const next = () => {
    if ($("[value=radioButton]:checked").length >= 0) {
      if (count === 3) {
        alert("Finished Questionnaire");
      }

      resetRadios();
      setCount(count + 1);
      getCategories();
    } else {
      alert("Complete at least one question");
    }
  };

  const resetRadios = () => {
    $("[value=radioButton]").prop("checked", false);
  };

  const addResponse = (data) => {
    let newResponses = [];
    newResponses = [...responses, data];
    console.log(newResponses);
    setResponses(newResponses);
  };

  const removeResponse = (data) => {
    let newResponses = [];
    let index = -1;
    newResponses = responses;
    if (newResponses.length > 0) {
      newResponses.map((x, arrIndex) => {
        if (JSON.stringify(x) === JSON.stringify(data)) {
          index = arrIndex;
          console.log("Match Data:" + data + " response: " + x);
        }
      });
      if (index !== -1) {
        newResponses.splice(index, 1);
      }
      setResponses(newResponses);
    }
  };

  const recordResponse = () => {
    responses.map((x) => {
      Axios.post(
        `${URL}/response/${x.count}/${x.cat_id}/${x.design}/${x.proj_id}`
      ).then(() => {
        console.log("Response Recorded");
        setResponses([]);
        getCount();
        updateDate();
      });
    });
  };

  // const testData = () => {
  //   console.log(responses);
  // };

  useEffect(() => {
    getCount();
    GetApproach();
  }, []);

  return (
    <Container>
      <Box sx={{ mt: 5 }}>
        <Box sx={{ overflow: "auto", height: 400 }}>
          <div>
            {categories.map((categoryItem, key) => {
              return (
                <Questionnaire
                  key={key}
                  category={categoryItem.CATEGORY}
                  cat_id={categoryItem.CAT_ID}
                  cat_order={categoryItem.CAT_ORDER}
                  count={count}
                  proj_id={proj_id}
                  addResponse={addResponse}
                  removeResponse={removeResponse}
                  designs={designs}
                  approach={approach}
                  expanded={expanded}
                  setExpanded={setExpanded}
                />
              );
            })}
          </div>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Button
            variant="outlined"
            fullWidth
            onClick={() => {
              next();

              recordResponse();
            }}
          >
            Next
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default QuestionnairePage;
