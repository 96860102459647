import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";

function AdminRoute() {
  const { userRole } = useContext(AuthContext);

  if (userRole === "USER") {
    return <Navigate to="/login" />;
  } else {
    return <Outlet />;
  }
}

export default AdminRoute;
