import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { max } from "date-fns";
import { URL } from "../config/config";

import "../styles/Project.css";

import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

function Project() {
  const [project, setProject] = useState([]);
  const proj_id = localStorage.getItem("proj_id");
  const navigate = useNavigate();

  const [deleteProj, setDeleteProj] = useState(false);
  const [validName, setValidName] = useState(false);

  const [approach, setApproach] = useState();
  const [validApproach, setValidApproach] = useState(false);

  const [count, setCount] = useState();
  const [quesCompleted, setQuesCompleted] = useState(false);
  const [quesStarted, setQuesStarted] = useState(false);

  const [design, setDesign] = useState();

  const SetApproach = (approach) => {
    const data = { approach: approach };
    Axios.put(`${URL}/project/approach/${proj_id}`, data).then((response) => {
      if (response.data.error) {
        console.log(response.data.error);
      }
      updateDate();
    });
  };

  const GetApproach = () => {
    Axios.get(`${URL}/project/approach/${proj_id}`).then((response) => {
      setApproach(response.data[0].APPROACH);
      if (response.data[0].APPROACH === "QUALITATIVE") {
        // document.getElementById("QUALITATIVE").checked = true;
      }
      if (response.data[0].APPROACH === "QUANTITATIVE") {
        // document.getElementById("QUANTITATIVE").checked = true;
      }
    });
  };

  const GetProject = () => {
    Axios.get(`${URL}/project/${proj_id}`).then((response) => {
      if (response.data.error) {
        console.log(response.data.error);
      } else {
        setProject(response.data[0]);
        document.getElementById("name").value = response.data[0].NAME;
        document.getElementById("description").value =
          response.data[0].DESCRIPTION;
        if (response.data[0].APPROACH_ID === null) {
          setValidApproach(false);
        } else {
          setValidApproach(true);
          GetApproach();
        }
      }
    });
  };

  const updateDate = () => {
    Axios.put(`${URL}/project/date/${proj_id}`).then((response) => {
      if (response.data.error) {
        console.log(response.data.error);
      }
    });
  };

  const getCount = () => {
    Axios.get(`${URL}/response/count/${proj_id}`).then((response) => {
      if (response.data[0].count === null) {
        setCount(0);
      } else {
        setCount(response.data[0].count);
        setQuesStarted(true);
        if (response.data[0].count >= 3) {
          setQuesCompleted(true);
          getDesign();
        }
      }
      console.log("Count Loaded");
    });
  };

  const getDesign = () => {
    Axios.get(`${URL}/response/${proj_id}`).then((response) => {
      setDesign(response.data[0].DESIGN);
    });
  };

  const DeleteProj = () => {
    Axios.delete(`${URL}/project/${proj_id}`).then((response) => {
      console.log("Project Deleted!");
    });
    // navigate("/projects");
  };

  const EditName = (value) => {
    // setEditName(false);
    console.log(value);
    const data = { name: value };
    Axios.put(`${URL}/project/name/${proj_id}`, data);
    updateDate();
  };

  const EditDescription = (value) => {
    // setEditDescription(false);
    const data = { description: value };
    Axios.put(`${URL}/project/description/${proj_id}`, data);
    updateDate();
  };

  // const showData = () => {
  //   // console.log(project);
  //   // document.getElementById("name").defaultValue = "Test";
  // };

  useEffect(() => {
    GetProject();
    getCount();
  }, []);

  return (
    <Container maxWidth="xl">
      <Box
        //Main Box

        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            //Project Box

            alignItems: "center",
            justifyContent: "center",
            boxShadow: 1,

            minWidth: { md: 500 },
            height: 530,
            mt: 3,
          }}
        >
          <div id={deleteProj ? "hide" : "show"}>
            <Box
              // boxShadow={2}
              sx={{ m: 3, p: 3 }}
            >
              <TextField
                fullWidth
                type="text"
                className="input"
                id="name"
                label={"Name"}
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={project.NAME}
                onChange={(event) => {
                  EditName(event.target.value);
                }}
              />

              <TextField
                multiline
                fullWidth
                rows={4}
                id="description"
                label={"Description"}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event) => {
                  EditDescription(event.target.value);
                }}
                sx={{ mt: 3 }}
              ></TextField>
            </Box>
            <Box boxShadow={0} sx={{ m: 5, mt: 2, p: 3 }}>
              <div className="approach" id={quesStarted ? "hide" : "show"}>
                <FormControl>
                  <FormLabel>Approach</FormLabel>
                  <RadioGroup row>
                    <FormControlLabel
                      value="quantitative"
                      control={<Radio />}
                      label="Quantitative"
                      onClick={() => {
                        setApproach("Quantitative");

                        SetApproach("Quantitative");

                        setValidApproach(true);
                      }}
                    />
                    <FormControlLabel
                      value="qualitative"
                      control={<Radio />}
                      label="Qualitative"
                      onClick={() => {
                        setApproach("Quantitative");

                        SetApproach("Qualitative");

                        setValidApproach(true);
                      }}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <Box boxShadow={0} sx={{ alignContent: "space-around" }}>
                <div id={quesCompleted ? "hide" : "show"}>
                  <div id={quesStarted ? "show" : "hide"}>
                    <Typography variant="body1">
                      Complete Questionnaire
                    </Typography>

                    <Typography variant="body1" sx={{ mt: 1, mb: 1 }}>
                      Questionnaires filled out: {count}
                    </Typography>

                    <div id={validApproach ? "show" : "hide"}>
                      <Typography variant="body1">
                        Approach: {approach}
                      </Typography>
                    </div>
                  </div>
                </div>
                <div id={quesCompleted ? "show" : "hide"}>
                  <Typography variant="body1">
                    Questionnaire Completed
                  </Typography>

                  <Typography variant="body1">Design: {design}</Typography>
                  <div
                    id={validApproach ? "show" : "hide"}
                    className="approach-output"
                  >
                    <Typography variant="body1">
                      Approach: {approach}
                    </Typography>
                  </div>
                </div>
              </Box>
            </Box>
            <Box
              className="nav"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: max,
              }}
            >
              <Box sx={{ alignItems: "flex-start", pl: 5 }}>
                <div id={quesStarted ? "hide" : "show"}>
                  <Link to={"/project/questionnaire"}>
                    <Button
                      variant="outlined"
                      size="large"
                      disabled={!validApproach}
                    >
                      Start Questionnaire
                    </Button>
                  </Link>
                </div>
                <div id={quesCompleted ? "hide" : "show"}>
                  <div id={quesStarted ? "show" : "hide"}>
                    <Link to={"/project/questionnaire"}>
                      <Button variant="outlined" size="large">
                        Continue Questionnaire
                      </Button>
                    </Link>
                  </div>
                </div>
                <div className="post-ques" id={quesCompleted ? "show" : "hide"}>
                  <Link to={"/project/report"}>
                    <Button variant="outlined" size="large">
                      View Report
                    </Button>
                  </Link>
                </div>
              </Box>

              <Box sx={{ alignItems: "flex-end", pr: 5 }}>
                <Button
                  variant="outlined"
                  size="large"
                  startIcon={<DeleteIcon />}
                  color="error"
                  onClick={() => {
                    setDeleteProj(true);
                  }}
                >
                  Delete
                </Button>
              </Box>
            </Box>
          </div>
          <div id={deleteProj ? "show" : "hide"}>
            <Box sx={{ m: 3 }}>
              <Typography variant="h5">
                To delete this project type "{project.NAME}"
              </Typography>

              <TextField
                fullWidth
                id="proj-name"
                sx={{ mb: 5 }}
                type="text"
                placeholder={project.NAME}
                onChange={(event) => {
                  if (event.target.value === project.NAME) {
                    setValidName(true);
                  } else {
                    setValidName(false);
                  }
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: max,
                }}
              >
                <Button
                  disabled={!validName}
                  variant="outlined"
                  size="large"
                  startIcon={<DeleteIcon />}
                  color="error"
                  onClick={() => {
                    DeleteProj();
                    navigate("/projects");
                  }}
                >
                  Delete
                </Button>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={() => {
                    setDeleteProj(false);
                    document.getElementById("proj-name").value = "";
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </div>
        </Box>
      </Box>
    </Container>
  );
}

export default Project;
