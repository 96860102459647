import React, { useEffect, useState } from "react";

// import "../styles/components/QuestionItem.css";
import { Box } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { Typography } from "@mui/material";

function Question({
  question,
  design,
  count,
  cat_id,
  proj_id,
  addResponse,
  removeResponse,
}) {
  const [disabledYes, setDisabledYes] = useState(false);
  const [disabledNo, setDisabledNo] = useState(false);

  useEffect(() => {
    setDisabledYes(false);
    setDisabledNo(false);
  }, [question]);

  return (
    <Box
      boxShadow={0}
      component="span"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        mt: 2,
      }}
    >
      <Box boxShadow={0} sx={{ alignItems: "flex-start" }}>
        {/* <p className="question">{question}</p> */}
        <Typography variant="body1">{question}</Typography>
      </Box>
      <Box boxShadow={0} sx={{ display: "flex", alignItems: "flex-end" }}>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            sx={{ display: "flex" }}
          >
            <FormControlLabel
              value={"yes"}
              control={<Radio />}
              label="Yes"
              disabled={disabledYes}
              onClick={() => {
                addResponse({ count, cat_id, design, proj_id });
                setDisabledYes(true);
                setDisabledNo(false);
              }}
            />
            <FormControlLabel
              value={"no"}
              control={<Radio />}
              label="No"
              disabled={disabledNo}
              onClick={() => {
                removeResponse({ count, cat_id, design, proj_id });
                setDisabledNo(true);
                setDisabledYes(false);
              }}
            />
          </RadioGroup>
        </FormControl>
      </Box>
    </Box>
  );
}

export default Question;
