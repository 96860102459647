import "./App.css";
import Navbar from "./components/Navbar";
import ProtectedRoute from "./routes/ProtectedRoute";
//Pages
import Home from "./pages/Home";
import About from "./pages/About";
import AllProjects from "./pages/AllProjects";
import Project from "./pages/Project";
import NewProject from "./pages/NewProject";
import NotFound from "./pages/NotFound";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Report from "./pages/Report";
import Help from "./pages/Help";
import QuestionnairePage from "./pages/QuestionnairePage";
import { AuthContext } from "./contexts/AuthContext";

import { URL } from "../src/config/config";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useState, useEffect } from "react";
import Axios from "axios";
import AdminRoute from "./routes/AdminRoute";
import "@fontsource/roboto/300.css";

function App() {
  const [userToken, setUserToken] = useState(localStorage.getItem("userToken"));
  const [admin, setAdmin] = useState(false);

  const Authenticate = () => {
    Axios.get(`${URL}/auth`, {
      headers: { userToken: userToken },
    }).then((response) => {
      if (response.data.error) {
        setUserToken(undefined);
      } else {
        // setUserRole(response.data);
        if (response.data === "USER") {
          setAdmin(false);
        } else {
          setAdmin(true);
        }
      }
    });
  };

  useEffect(() => {
    Authenticate();
  });

  return (
    <div>
      <AuthContext.Provider
        value={{ userToken, setUserToken, admin, setAdmin }}
      >
        <Router>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route element={<ProtectedRoute />}>
              {/* <Route path="/user_home" element={<UserHome />} /> */}
              <Route path="/projects" element={<AllProjects />} />
              <Route path="/new_project" element={<NewProject />} />
              <Route path="/project" element={<Project />} />
              <Route
                path="/project/questionnaire"
                element={<QuestionnairePage />}
              />
              <Route path="/project/report" element={<Report />} />
              <Route path="/help" element={<Help />} />
            </Route>
            <Route path="/about" element={<About />} />
            <Route element={<AdminRoute />}>
              <Route path="/register" element={<Register />} />
            </Route>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
