import React, { useEffect, useState } from "react";
import Axios from "axios";
import { URL } from "../config/config";

import AwnserItem from "../components/AwnserItem";

import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import { Container } from "@mui/material";

function Report() {
  const [design, setDesign] = useState();
  const [designID, setDesignID] = useState();
  const [categories, setCategories] = useState([]);

  const updateDate = () => {
    Axios.put(`${URL}/project/date/${proj_id}`).then((response) => {
      if (response.data.error) {
        console.log(response.data.error);
      }
    });
  };

  const proj_id = localStorage.getItem("proj_id");

  const getDesignCategories = () => {
    Axios.get(`${URL}/response/${proj_id}`).then((response) => {
      setDesign(response.data[0].DESIGN);
      setDesignID(response.data[0].DESIGN_ID);
      console.log("Design Loaded");
      Axios.get(`${URL}/category/first`).then((response) => {
        setCategories(response.data);
        console.log("Categories Loaded");
      });
      updateDate();
    });
  };

  const testData = () => {
    console.log(categories);
  };

  useEffect(() => {
    getDesignCategories();
  }, []);

  return (
    <Container>
      <Box sx={{ mt: 3 }}>
        <Typography variant="h3">{design}</Typography>
        <Box boxShadow={0} sx={{ overflow: "auto", height: 400 }}>
          {categories.map((categoryItem, key) => {
            return (
              <AwnserItem
                key={key}
                category={categoryItem.CATEGORY}
                categoryId={categoryItem.CAT_ID}
                designId={designID}
              />
            );
          })}
        </Box>

        {/* <button onClick={testData}>Show data</button> */}
      </Box>
    </Container>
  );
}

export default Report;
