import React, { useEffect, useState } from "react";
// import "../styles/components/ProjectItem.css";
import { Link } from "react-router-dom";
import { format } from "date-fns";

import Grid from "@mui/material/Grid";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

function Project({ id, name, description, date, getProjects }) {
  const [formattedDate, setFormattedDate] = useState();
  const [formattedTime, setFormattedTime] = useState();

  const setId = () => {
    localStorage.setItem("proj_id", id);
  };

  const formatDate = () => {
    setFormattedDate(format(new Date(date), "yyyy/MM/dd"));
    setFormattedTime(format(new Date(date), "hh:mm"));
  };

  useEffect(() => {
    formatDate();
  }, []);

  return (
    <Grid item xs={12} md={4}>
      <Box boxShadow={4} sx={{ height: 250, alignItems: "center", p: 3 }}>
        <Typography variant="subtitle1" fontWeight={"bold"}>
          {name}
        </Typography>
        <Typography variant="subtitle2" component="div">
          {formattedDate}, {formattedTime}
        </Typography>
        <Box
          sx={{ m: 3, height: 120, alignContent: "center", overflow: "hidden" }}
        >
          <Typography variant="body2">{description}</Typography>
        </Box>
        <Link to={`/project`}>
          <Button
            fullWidth
            className="project-btn"
            variant="outlined"
            onClick={setId}
          >
            Select
          </Button>
        </Link>
      </Box>
    </Grid>
  );
}

export default Project;
