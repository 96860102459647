import React from "react";
// import "../styles/NewProject.css";
import { Link } from "react-router-dom";
import Axios from "axios";
import { useState } from "react";
import { URL } from "../config/config";

import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import { TextField } from "@mui/material";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";

function NewProject() {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const createProject = () => {
    const data = { name: name, des: description };
    Axios.post(`${URL}/project`, data, {
      headers: { userToken: localStorage.getItem("userToken") },
    }).then(() => {
      console.log("Project Added");
    });
    console.log("Added");
  };

  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          // component="form"
          sx={{
            marginTop: 2,
            p: 3,
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
            minWidth: { md: 500 },
            boxShadow: 2,
          }}
        >
          <Typography variant="h3">New Project</Typography>

          <Box component={"form"} sx={{}}>
            <div>
              <TextField
                sx={{ mt: 3 }}
                fullWidth
                variant="outlined"
                label="Project Name"
                onChange={(event) => {
                  setName(event.target.value);
                }}
              ></TextField>
            </div>
            <TextField
              sx={{ mt: 3 }}
              fullWidth
              variant="outlined"
              multiline
              rows={4}
              label="Project Description"
              onChange={(event) => {
                setDescription(event.target.value);
              }}
            ></TextField>
          </Box>
          <Box sx={{ mt: 3 }}>
            <Link to="/projects">
              <Button
                fullWidth
                variant="contained"
                size="large"
                onClick={createProject}
              >
                Submit
              </Button>
            </Link>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

export default NewProject;
