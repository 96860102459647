import React, { useState, useEffect } from "react";
import QuestionItem from "./QuestionItem";
import Axios from "axios";
import { URL } from "../config/config";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";

function Questionnaire({
  category,
  cat_id,
  count,
  proj_id,
  addResponse,
  removeResponse,
  designs,
  approach,
  expanded,
  setExpanded,
}) {
  const [questions, setQuestions] = useState([]);

  const getQuestions = () => {
    const data = { approach: approach };

    Axios.get(`${URL}/question/${count}/${cat_id}/${approach}`, data).then(
      (response) => {
        console.log("Designs " + designs);
        // console.log(count);
        if (count === 1) {
          setQuestions(response.data);
        } else {
          let newQuestions = [];
          response.data.map((x) => {
            designs.map((y) => {
              if (x.DESIGN_ID === y.DESIGN_ID) {
                newQuestions = [...newQuestions, x];
                setQuestions(newQuestions);
              }
            });
          });
        }
      }
    );
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    getQuestions();
  }, [count, designs]);

  return (
    <div>
      {/* <button onClick={showdata}>Show Data</button> */}
      {/* <h3>{category}</h3> */}
      <div>
        <Accordion
          expanded={expanded === category}
          onChange={handleChange(category)}
          sx={{ mt: 1 }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{category}</Typography>
          </AccordionSummary>

          {questions.map((questionItem, key) => {
            return (
              <QuestionItem
                key={key}
                question={questionItem.QUESTION}
                design={questionItem.DESIGN_ID}
                count={count}
                cat_id={cat_id}
                proj_id={proj_id}
                addResponse={addResponse}
                removeResponse={removeResponse}
              />
            );
          })}
        </Accordion>
      </div>
    </div>
  );
}

export default Questionnaire;
