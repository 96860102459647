// import React, { useContext } from "react";
// import "../styles/Navbar.css";
// import { Link } from "react-router-dom";
// import { AuthContext } from "../contexts/AuthContext";

// function Navbar() {
//   const { userToken, setUserToken, admin, setAdmin } = useContext(AuthContext);

//   const logOut = () => {
//     localStorage.removeItem("userToken");
//     setUserToken(undefined);
//     setAdmin(false);
//   };

//   return (
//     <div className="navbar">
//       <div className="nav-child">
//         <div className="pages" id={userToken ? "show" : "hide"}>
//           {/* <Link to="/user_home">User Home</Link> */}
//           <Link to="projects">Projects</Link>
//           <Link to="/about">About</Link>
//         </div>
//         <div className="admin-pages" id={admin ? "show" : "hide"}>
//           <Link to="/register">Register</Link>
//         </div>
//       </div>
//       <div className="nav-child">
//         <div className="nav-buttons" id={userToken ? "hide" : "show"}>
//           <Link to="/login">
//             <button className="action-btn">Log In</button>
//           </Link>
//         </div>
//         <div className="profile" id={userToken ? "show" : "hide"}>
//           <Link to="/">
//             <button className="action-btn" onClick={logOut}>
//               Log Out
//             </button>
//           </Link>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Navbar;

import React, { useContext } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
// import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
// import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";

import Intelliplanner from "../assets/images/Intelliplanner.png";

import { Link } from "react-router-dom";

import { AuthContext } from "../contexts/AuthContext";

// const pages = ["Products", "Pricing", "Blog"];

const pages = [
  { name: "Projects", path: "/projects" },
  { name: "Help", path: "/help" },
  { name: "About", path: "/about" },
];

// const settings = ["Profile", "Account", "Dashboard"];

const Navbar = () => {
  const { userToken, setUserToken, admin, setAdmin } = useContext(AuthContext);

  const logOut = () => {
    localStorage.removeItem("userToken");
    setUserToken(undefined);
    setAdmin(false);
  };

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="sticky">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
          >
            LOGO
          </Typography> */}
          <Box
            component="img"
            sx={{ width: 100, mr: 2, display: { xs: "none", md: "flex" } }}
            alt="Logo Image"
            src={Intelliplanner}
          />
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <div id={userToken ? "show" : "show"}>
                {pages.map((page, key) => (
                  <MenuItem key={key} onClick={handleCloseNavMenu}>
                    <Link to={page.path} style={{ textDecoration: "none" }}>
                      <Typography textAlign="center">{page.name}</Typography>
                    </Link>
                  </MenuItem>
                ))}
              </div>
            </Menu>
          </Box>
          {/* <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
          >
            LOGO
          </Typography> */}
          <Box
            component="img"
            sx={{
              width: 100,
              ml: 1 / 4,
              mr: 10,

              // flexGrow: 1,
              display: { xs: "flex", md: "none" },
            }}
            alt="Logo Image"
            src={Intelliplanner}
          />
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <div id={userToken ? "show" : "hide"}>
              {pages.map((page, key) => (
                <Link
                  to={page.path}
                  key={key}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    onClick={handleCloseNavMenu}
                    sx={{
                      my: 2,
                      color: "white",
                      display: "block",
                    }}
                  >
                    {page.name}
                  </Button>
                </Link>
              ))}
              <Link to="/register" style={{ textDecoration: "none" }}>
                <Button
                  id={admin ? "show" : "hide"}
                  sx={{
                    my: 2,
                    color: "white",
                    display: "block",
                  }}
                >
                  Register
                </Button>
              </Link>
            </div>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Link to="/" style={{ textDecoration: "none" }}>
              <Button
                onClick={logOut}
                variant="contained"
                id={userToken ? "show" : "hide"}
              >
                Logout
              </Button>
            </Link>
            <Link to="/login" style={{ textDecoration: "none" }}>
              <Button variant="contained" id={userToken ? "hide" : "show"}>
                Log In
              </Button>
            </Link>
            {/* <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu> */}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Navbar;
