// import { useState, useContext } from "react";
// import React from "react";
// import { Link, useNavigate } from "react-router-dom";
// import Axios from "axios";
// import { AuthContext } from "../contexts/AuthContext";

// function Login() {
//   const [username, setUsername] = useState();
//   const [password, setPassword] = useState();

//   const { setUserToken, setAdmin } = useContext(AuthContext);
//   const navigate = useNavigate();

//   const getUserRole = (userToken) => {
//     Axios.get(`http://localhost:8091/auth`, {
//       headers: { userToken: userToken },
//     }).then((response) => {
//       if (!response.data.error) {
//         if (response.data === "USER") {
//           setAdmin(false);
//         } else {
//           setAdmin(true);
//         }
//       }
//     });
//   };

//   const logIn = () => {
//     const data = { username: username, password: password };

//     Axios.post(`http://localhost:8091/login`, data).then((response) => {
//       if (response.data.error) {
//         alert(response.data.error);
//       } else {
//         localStorage.setItem("userToken", response.data);
//         setUserToken(response.data);
//         getUserRole(response.data);
//         navigate("/projects");
//       }
//     });
//   };

//   return (
//     <div>
//       <form>
//         <label>
//           Username
//           <input
//             type="text"
//             className="username"
//             onChange={(event) => {
//               setUsername(event.target.value);
//             }}
//           />
//         </label>
//         <label>
//           Password
//           <input
//             type="password"
//             className="password"
//             onChange={(event) => {
//               setPassword(event.target.value);
//             }}
//           />
//         </label>
//       </form>
//       <button onClick={logIn}>Log In</button>
//       <Link to={"/"}>
//         <button>Cancel</button>
//       </Link>
//     </div>
//   );
// }

// export default Login;

import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { URL } from "../config/config";

import Axios from "axios";
import { useState, useContext } from "react";
import { Link as ReactLink, useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function SignIn() {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console

    // email: data.get("email"),
    // password: data.get("password"),
    logIn(data.get("email"), data.get("password"));
  };

  const logIn = (username, password) => {
    const data = { username: username, password: password };

    Axios.post(`${URL}/login`, data).then((response) => {
      if (response.data.error) {
        alert(response.data.error);
      } else {
        localStorage.setItem("userToken", response.data);
        setUserToken(response.data);
        getUserRole(response.data);
        navigate("/projects");
      }
    });
  };

  const { setUserToken, setAdmin } = useContext(AuthContext);
  const navigate = useNavigate();

  const getUserRole = (userToken) => {
    Axios.get(`${URL}/auth`, {
      headers: { userToken: userToken },
    }).then((response) => {
      if (!response.data.error) {
        if (response.data === "USER") {
          setAdmin(false);
        } else {
          setAdmin(true);
        }
      }
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={logIn}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
