import React from "react";
// import "../styles/Home.css";

import Intelliplanner from "../assets/images/Intelliplanner.png";

import { Container } from "@mui/material";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";

function home() {
  return (
    <Container>
      <Box
        sx={{
          alignItems: "center",

          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          // verticalAlign: "center",
        }}
      >
        <Box
          component="img"
          sx={{ width: 200, mt: 10 }}
          alt="Logo Image"
          src={Intelliplanner}
        />
        <Typography variant="h1" sx={{ mb: 2 }}>
          Intelliplanner
        </Typography>

        <Typography variant="h4">Plan your research</Typography>

        <Box sx={{ mt: 10 }}>
          <Typography variant="h6">What is intelliplanner?</Typography>

          <Typography variant="body1">
            Intelliplanner is a software solution that enables you to plan an
            approach to your research.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}

export default home;
