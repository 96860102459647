import Axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { URL } from "../config/config";

function Register() {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();

  const RegisterUser = () => {
    const data = { username: username, password: password, role_id: "1" };

    Axios.post(`${URL}/register`, data).then((response) => {
      alert("User Registerd");

      $(".username").val("");
      $(".password").val("");
    });
  };

  return (
    <div>
      <form>
        <label>
          Username
          <input
            type="text"
            className="username"
            onChange={(event) => {
              setUsername(event.target.value);
            }}
          />
        </label>
        <label>
          Password
          <input
            type="text"
            className="password"
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          />
        </label>
        {/* <label>
          Confirm Password
          <input type="password" className="confirm-password" />
        </label> */}
      </form>
      <button onClick={RegisterUser}>Register</button>
      <Link to={"/projects"}>
        <button>Cancel</button>
      </Link>
    </div>
  );
}

export default Register;
