import React, { useEffect } from "react";
// import "../styles/AllProjects.css";
import ProjectItem from "../components/ProjectItem";
import Axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";
import { URL } from "../config/config";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";

function AllProjects() {
  const [projectList, setProjectList] = useState([]);

  const getProjects = () => {
    Axios.get(`${URL}/projects`, {
      headers: { userToken: localStorage.getItem("userToken") },
    }).then((response) => {
      if (!response.data.error) {
        setProjectList(response.data);
        console.log("Projects Loaded");
      }
    });
  };

  useEffect(() => {
    getProjects();
  }, []);

  return (
    <div className="App">
      <Container maxWidth="xl">
        <Box
          component="span"
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
            justifyContent: "space-between",
            bgcolor: "background.paper",
            overflow: "hidden",
            borderRadius: "12px",
            // fontWeight: "bold",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: { xs: "center", md: "flex-start" },
              m: 3,
              minWidth: { md: 350 },
            }}
          >
            <Typography variant="h3" fontWeight={"normal"}>
              Projects
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: { xs: "center", md: "flex-end" },
              m: 3,
              minWidth: { md: 350 },
            }}
          >
            <Link to={"/new_project"}>
              <Button size="large" variant="outlined">
                +
              </Button>
            </Link>
          </Box>
        </Box>

        <Grid container spacing={3} alignItems="center">
          {projectList.map((projectItem, key) => {
            return (
              <ProjectItem
                key={key}
                id={projectItem.PROJ_ID}
                name={projectItem.NAME}
                date={projectItem.DATE_USED}
                description={projectItem.DESCRIPTION}
                getProjects={getProjects}
              />
            );
          })}
        </Grid>
      </Container>
    </div>
  );
}

export default AllProjects;
